import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import GatsbyLink from 'gatsby-link';
import { gridTemplateColumns } from 'styled-system';

import { Box } from '@square-enix-private/vaquita-ui';
import { Item } from './elements';

export const VideoList = styled(Box)`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 580px) {
    display: grid;
    ${gridTemplateColumns};
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 32px;
    grid-auto-rows: auto;
  }
`;

const Link = styled(GatsbyLink)`
  width: 100%;
  text-decoration: none;
  color: inherit;
`;

const VideosViewer = ({ items, placeholders, locale }) => {
  const gridTemplateColumns = items.length + placeholders.length === 1
    ? [null, 'repeat(1, 1fr)']
    : [null, 'repeat(2, 1fr)', 'repeat(3, 1fr)'];

  return (
    <VideoList width="100%" gridTemplateColumns={gridTemplateColumns}>
      {items.map(({ video, title, id, slug, alt }) => (
        <Link to={`${locale}/broadcast/${slug}`} key={id}>
          <Item
            width={1}
            imgData={video.image}
            alt={alt || video.alt}
            description={title}
            playVisible
          />
        </Link>
      ))}
      {placeholders.map(({ image, title, id }) => (
        <Item width={1} key={id} imgData={image} description={title} />
      ))}
    </VideoList>
  );
};

VideosViewer.propTypes = {
  items: PropTypes.array.isRequired,
  placeholders: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired
};

export default VideosViewer;
